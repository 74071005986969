


const localPages = [
    //TODO Update images and card links
    // Atlanta
    {
        city: "Atlanta",
        slug: "/atlanta/",
        seoTitle: "Atlanta | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From Midtown to Atlantic Station, Grubhub Corporate Accounts has Atlanta's corporate food ordering covered with group orders, catering and more.",
        headline: "Atlanta Test Headline",
        headerPattern:"pizza",
        restaurantCards: [
            {
                title:"DaVinci's Pizzeria",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887546/Grubhub%20Corporate%20Website/2021/City%20Pages/Atlanta/RestaurantImages-ATLANTA-DaVincis.png",
            },
            {
                title:"Moe's Original BBQ",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887548/Grubhub%20Corporate%20Website/2021/City%20Pages/Atlanta/RestaurantImages-ATLANTA-MoesBBQ.png",
            },
            {
                title:"Landmark Diner",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887546/Grubhub%20Corporate%20Website/2021/City%20Pages/Atlanta/RestaurantImages-AUSTIN-LandmarkDiner.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Atlanta?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Atlanta.</span>"
            },
            {
                header: "How does Group Order work in Atlanta?",
                description: "<span>Employees in Atlanta place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Atlanta?",
                description: "<span>Individual meals ordered in Atlanta allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Atlanta?",
                description: "Delivery distances in Atlanta vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Atlanta?",
                description: "You can order online from a variety of restaurants throughout Atlanta. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Atlanta?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Atlanta, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Atlanta?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Atlanta?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Austin
    {
        city: "Austin",
        slug: "/austin/",
        seoTitle: "Austin | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From South Congress to Downtown, Grubhub Corporate Accounts has Austin's corporate food ordering covered with group orders, catering and more.",
        headline: "Austin Test Headline",
        headerPattern:"poke",
        restaurantCards: [
            {
                title:"Donn's BBQ",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887547/Grubhub%20Corporate%20Website/2021/City%20Pages/Austin/RestaurantImages-AUSTIN-DonnsBBQ.png",
            },
            {
                title:"Trudy's",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887546/Grubhub%20Corporate%20Website/2021/City%20Pages/Austin/RestaurantImages-AUSTIN-Trudys.png",
            },
            {
                title:"Torchy's Tacos",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887546/Grubhub%20Corporate%20Website/2021/City%20Pages/Austin/RestaurantImages-AUSTIN-Torchys.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Austin?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Austin.</span>"
            },
            {
                header: "How does Group Order work in Austin?",
                description: "<span>Employees in Austin place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Austin?",
                description: "<span>Individual meals ordered in Austin allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Austin?",
                description: "Delivery distances in Austin vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Austin?",
                description: "You can order online from a variety of restaurants throughout Austin. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Austin?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Austin, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Austin?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Austin?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Boston
    {
        city: "Boston",
        slug: "/boston/",
        seoTitle: "Boston | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From Back Bay to Brookline, Grubhub Corporate Accounts has Boston's corporate food ordering covered with group orders, catering and more.",
        headline: "Boston Test Headline",
        headerPattern:"pizza",
        restaurantCards: [
            {
                title:"Sam LaGrassa's",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887547/Grubhub%20Corporate%20Website/2021/City%20Pages/Boston/RestaurantImages-BOSTON-SamLagrass.png",
            },
            {
                title:"Regina Pizzeria",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887546/Grubhub%20Corporate%20Website/2021/City%20Pages/Boston/RestaurantImages-BOSTON-Reginas.png",
            },
            {
                title:"Mike’s Pastry",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887546/Grubhub%20Corporate%20Website/2021/City%20Pages/Boston/RestaurantImages-BOSTON-MikesPastry.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Boston?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Boston.</span>"
            },
            {
                header: "How does Group Order work in Boston?",
                description: "<span>Employees in Boston place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Boston?",
                description: "<span>Individual meals ordered in Boston allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Boston?",
                description: "Delivery distances in Boston vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Boston?",
                description: "You can order online from a variety of restaurants throughout Boston. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Boston?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Boston, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Boston?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Boston?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Cambridge
    {
        city: "Cambridge",
        slug: "/cambridge/",
        seoTitle: "Cambridge | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From Kendall Square to West Cambridge, Grubhub Corporate Accounts has Cambridge's corporate food ordering covered with group orders, catering and more.",
        headline: "Cambridge Test Headline",
        headerPattern:"cake",
        restaurantCards: [
            {
                title:"Sapporo Ramen",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887547/Grubhub%20Corporate%20Website/2021/City%20Pages/Cambridge/RestaurantImages-CAMBRIDGE-SaporoRamen.png",
            },
            {
                title:"Noor's",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887546/Grubhub%20Corporate%20Website/2021/City%20Pages/Cambridge/RestaurantImages-CAMBRIDGE-Noors.png",
            },
            {
                title:"JP Licks",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887548/Grubhub%20Corporate%20Website/2021/City%20Pages/Cambridge/RestaurantImages-CAMBRIDGE-JP-Licks.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Cambridge?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Cambridge.</span>"
            },
            {
                header: "How does Group Order work in Cambridge?",
                description: "<span>Employees in Cambridge place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Cambridge?",
                description: "<span>Individual meals ordered in Cambridge allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Cambridge?",
                description: "Delivery distances in Cambridge vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Cambridge?",
                description: "You can order online from a variety of restaurants throughout Cambridge. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Cambridge?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Cambridge, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Cambridge?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Cambridge?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Chicago
    {
        city: "Chicago",
        slug: "/chicago/",
        seoTitle: "Chicago | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From The Loop to Wrigleyville, Grubhub Corporate Accounts has Chicago's corporate food ordering covered with group orders, catering and more.",
        headline: "Chicago Test Headline",
        headerPattern:"hot dog",
        restaurantCards: [
            {
                title:"Lou Malnati's Pizzeria",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887548/Grubhub%20Corporate%20Website/2021/City%20Pages/Chicago/RestaurantImages-CHI-Lous.png",
            },
            {
                title:"Portillo's",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887548/Grubhub%20Corporate%20Website/2021/City%20Pages/Chicago/RestaurantImages-CHI-Portillos.png",
            },
            {
                title:"The Fudge Pot",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887549/Grubhub%20Corporate%20Website/2021/City%20Pages/Chicago/RestaurantImages-CHI-FudgePot.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Chicago?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Chicago.</span>"
            },
            {
                header: "How does Group Order work in Chicago?",
                description: "<span>Employees in Chicago place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Chicago?",
                description: "<span>Individual meals ordered in Chicago allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Chicago?",
                description: "Delivery distances in Chicago vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Chicago?",
                description: "You can order online from a variety of restaurants throughout Chicago. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Chicago?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Chicago, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Chicago?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Chicago?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Dallas
    {
        city: "Dallas",
        slug: "/dallas/",
        seoTitle: "Dallas | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From Uptown to Victory Park, Grubhub Corporate Accounts has Dallas' corporate food ordering covered with group orders, catering and more.",
        headline: "Dallas Test Headline",
        headerPattern:"nachos",
        restaurantCards: [
            {
                title:"Big Guys Chicken and Rice",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887548/Grubhub%20Corporate%20Website/2021/City%20Pages/Dallas/RestaurantImages-DALLAS-BigGuysChickenRicr.png",
            },
            {
                title:"E Bar Tex Mex",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887549/Grubhub%20Corporate%20Website/2021/City%20Pages/Dallas/RestaurantImages-DALLAS-EBarTexMex.png",
            },
            {
                title:"Hunky's Old Fashioned Burgers",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887549/Grubhub%20Corporate%20Website/2021/City%20Pages/Dallas/RestaurantImages-DALLAS-Hunkys.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Dallas?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Dallas.</span>"
            },
            {
                header: "How does Group Order work in Dallas?",
                description: "<span>Employees in Dallas place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Dallas?",
                description: "<span>Individual meals ordered in Dallas allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Dallas?",
                description: "Delivery distances in Dallas vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Dallas?",
                description: "You can order online from a variety of restaurants throughout Dallas. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Dallas?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Dallas, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Dallas?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Dallas?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Houston
    {
        city: "Houston",
        slug: "/houston/",
        seoTitle: "Houston | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From River Oaks to Montrose, Grubhub Corporate Accounts has Houston's corporate food ordering covered with group orders, catering and more.",
        headline: "Houston Test Headline",
        headerPattern:"poke",
        restaurantCards: [
            {
                title:"Chuy's",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887549/Grubhub%20Corporate%20Website/2021/City%20Pages/Houston/RestaurantImages-HOUSTON-Chuys.png",
            },
            {
                title:"The Burger Joint",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887550/Grubhub%20Corporate%20Website/2021/City%20Pages/Houston/RestaurantImages-HOUSTON-BurgerJoint.png",
            },
            {
                title:"Thai Spice Asian Cuisine",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887549/Grubhub%20Corporate%20Website/2021/City%20Pages/Houston/RestaurantImages-HOUSTON-ThaiSpiceAsian.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Houston?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Houston.</span>"
            },
            {
                header: "How does Group Order work in Houston?",
                description: "<span>Employees in Houston place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Houston?",
                description: "<span>Individual meals ordered in Houston allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Houston?",
                description: "Delivery distances in Houston vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Houston?",
                description: "You can order online from a variety of restaurants throughout Houston. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Houston?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Houston, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Houston?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Houston?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Los Angeles
    {
        city: "Los Angeles",
        slug: "/los-angeles/",
        seoTitle: "Los Angeles | Corporate Food Ordering | Grubhub Corporate",
        seoDescription: "From Hollywood to Santa Monica, Grubhub Corporate Accounts has Los Angeles' corporate food ordering covered with group orders, catering and more.",
        headline: "Los Angeles Test Headline",
        headerPattern:"sushi",
        restaurantCards: [
            {
                title:"Mel's Drive-In",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887549/Grubhub%20Corporate%20Website/2021/City%20Pages/Los%20Angeles/RestaurantImages-LA-MelsDriveIn.png",
            },
            {
                title:"Simply Salad",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887549/Grubhub%20Corporate%20Website/2021/City%20Pages/Los%20Angeles/simplysalad_header-1.jpg",
            },
            {
                title:"Edoughable",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887550/Grubhub%20Corporate%20Website/2021/City%20Pages/Los%20Angeles/RestaurantImages-LA-Edibough.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Los Angeles?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Los Angeles.</span>"
            },
            {
                header: "How does Group Order work in Los Angeles?",
                description: "<span>Employees in Los Angeles place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Los Angeles?",
                description: "<span>Individual meals ordered in Los Angeles allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Los Angeles?",
                description: "Delivery distances in Los Angeles vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Los Angeles?",
                description: "You can order online from a variety of restaurants throughout Los Angeles. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Los Angeles?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Los Angeles, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Los Angeles?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Los Angeles?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // New York
    {
        city: "New York",
        slug: "/new-york/",
        seoTitle: "New York | Corporate Food Ordering | Seamless Corporate",
        seoDescription: "From Manhattan to Brooklyn to Queens, Seamless Corporate Accounts has New York City's corporate food ordering covered with group orders, catering and more.",
        headline: "New York Test Headline",
        headerPattern:"pizza",
        restaurantCards: [
            {
                title:"Joe's Pizza",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887551/Grubhub%20Corporate%20Website/2021/City%20Pages/New%20York/RestaurantImages-NYC-Joes.png",
            },
            {
                title:"Ivan Ramen",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887550/Grubhub%20Corporate%20Website/2021/City%20Pages/New%20York/RestaurantImages-NYC-IvanRamen.png",
            },
            {
                title:"Baked by Melissa",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887550/Grubhub%20Corporate%20Website/2021/City%20Pages/New%20York/RestaurantImages-NYC-BakedByMellisa.png",
            }
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in New York?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in New York.</span>"
            },
            {
                header: "How does Group Order work in New York?",
                description: "<span>Employees in New York place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in New York?",
                description: "<span>Individual meals ordered in New York allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in New York?",
                description: "Delivery distances in New York vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in New York?",
                description: "You can order online from a variety of restaurants throughout New York. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in New York?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in New York, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in New York?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in New York?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Philadelphia
    {
        city: "Philadelphia",
        slug: "/philadelphia/",
        seoTitle: "Philadelphia | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From Old City to Universe City, Grubhub Corporate Accounts has Philadelphia's corporate food ordering covered with group orders, catering and more.",
        headline: "Philadelphia Test Headline",
        headerPattern:"salad",
        restaurantCards: [
            {
                title:"Pat’s King of Steaks",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887551/Grubhub%20Corporate%20Website/2021/City%20Pages/Philadelphia/RestaurantImages-PHILLY-PatsKingofSteaks.png",
            },
            {
                title:"Hatch and Coop",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887550/Grubhub%20Corporate%20Website/2021/City%20Pages/Philadelphia/RestaurantImages-PHILLY-HatchCoop.png",
            },
            {
                title:"Santuccis",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887551/Grubhub%20Corporate%20Website/2021/City%20Pages/Philadelphia/RestaurantImages-PHILLY-Santuccis.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Philadelphia?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Philadelphia.</span>"
            },
            {
                header: "How does Group Order work in Philadelphia?",
                description: "<span>Employees in Philadelphia place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Philadelphia?",
                description: "<span>Individual meals ordered in Philadelphia allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Philadelphia?",
                description: "Delivery distances in Philadelphia vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Philadelphia?",
                description: "You can order online from a variety of restaurants throughout Philadelphia. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Philadelphia?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Philadelphia, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Philadelphia?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Philadelphia?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // San Francisco
    {
        city: "San Francisco",
        slug: "/san-francisco/",
        seoTitle: "San Francisco | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From Nob Hill to Oakland, Grubhub Corporate Accounts has San Francisco's corporate food ordering covered with group orders, catering and more.",
        headline: "San Francisco Test Headline",
        headerPattern:"salad",
        restaurantCards: [
            {
                title:"Zaoh Restaurant",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887551/Grubhub%20Corporate%20Website/2021/City%20Pages/San%20Francisco/RestaurantImages-SF-Zaoh.png",
            },
            {
                title:"China Delights",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887551/Grubhub%20Corporate%20Website/2021/City%20Pages/San%20Francisco/RestaurantImages-SF-ChinaDelight.png",
            },
            {
                title:"The Baked Bar",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887551/Grubhub%20Corporate%20Website/2021/City%20Pages/San%20Francisco/RestaurantImages-SF-BakedBear.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in San Francisco?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in San Francisco.</span>"
            },
            {
                header: "How does Group Order work in San Francisco?",
                description: "<span>Employees in San Francisco place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in San Francisco?",
                description: "<span>Individual meals ordered in San Francisco allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in San Francisco?",
                description: "Delivery distances in San Francisco vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in San Francisco?",
                description: "You can order online from a variety of restaurants throughout San Francisco. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in San Francisco?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in San Francisco, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in San Francisco?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in San Francisco?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Seattle
    {
        city: "Seattle",
        slug: "/seattle/",
        seoTitle: "Seattle | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From Capitol Hill to Ballard, Grubhub Corporate Accounts has Seattle's corporate food ordering covered with group orders, catering and more.",
        headline: "Seattle Test Headline",
        headerPattern:"coffee",
        restaurantCards: [
            {
                title:"Great American Diner Bar",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887552/Grubhub%20Corporate%20Website/2021/City%20Pages/Seattle/RestaurantImages-SEATTLE-GreatAmericanDiner.png",
            },
            {
                title:"Genghis Khan Restaurant",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887552/Grubhub%20Corporate%20Website/2021/City%20Pages/Seattle/RestaurantImages-SEATTLE-GenghisKahn.png",
            },
            {
                title:"Mirch Masala",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887553/Grubhub%20Corporate%20Website/2021/City%20Pages/Seattle/RestaurantImages-SEATTLE-MirchMasala.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Seattle?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Seattle.</span>"
            },
            {
                header: "How does Group Order work in Seattle?",
                description: "<span>Employees in Seattle place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Seattle?",
                description: "<span>Individual meals ordered in Seattle allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Seattle?",
                description: "Delivery distances in Seattle vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Seattle?",
                description: "You can order online from a variety of restaurants throughout Seattle. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Seattle?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Seattle, it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Seattle?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Seattle?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
    // Washington D.C.
    {
        city: "Washington D.C.",
        slug: "/washington-dc/",
        seoTitle: "Washington D.C. | Corporate Food Ordering | Grubhub Corporate Accounts",
        seoDescription: "From Georgetown to Logan Circle, Grubhub Corporate Accounts has Washington D.C.'s corporate food ordering covered with group orders, catering and more.",
        headline: "Washington D.C. Test Headline",
        headerPattern:"nachos",
        restaurantCards: [
            {
                title:"A Baked Joint",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887552/Grubhub%20Corporate%20Website/2021/City%20Pages/Washington%20DC/RestaurantImages-DC-ABakedJoint.png",
            },
            {
                title:"Tortas Y Tacos La Chiquita",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887552/Grubhub%20Corporate%20Website/2021/City%20Pages/Washington%20DC/RestaurantImages-DC-TortasYTacosLaChiquita.png",
            },
            {
                title:"DCity Smokehouse",
                imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto,q_auto/v1626887552/Grubhub%20Corporate%20Website/2021/City%20Pages/Washington%20DC/RestaurantImages-DC-DCityBBQ.png",
            },
        ],
        faqs: [
            {
                header: "How do I open a Grubhub Corporate Account in Washington D.C.?",
                description: "<span>We offer various solutions for corporate accounts depending on your specific business needs. <a href='#'>Contact us</a> to learn more about getting started with a Corporate Account in Washington D.C..</span>"
            },
            {
                header: "How does Group Order work in Washington D.C.?",
                description: "<span>Employees in Washington D.C. place individual orders from pre-selected restaurants and the orders are delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. <a href='#'>Learn more about group order here.</a></span>"
            },
            {
                header: "How do individual meals work in Washington D.C.?",
                description: "<span>Individual meals ordered in Washington D.C. allow everyone to enjoy meals at home—no matter where they’re located—with allocated budgets. <a href='#'>Learn more about individual meals.</a></span>"
            },
            {
                header: "How far will Grubhub deliver in Washington D.C.?",
                description: "Delivery distances in Washington D.C. vary by restaurant. Go to Grubhub.com and enter your address to see which restaurants deliver to your office."
            },
            {
                header: "Can I use a Grubhub Corporate Account in Washington D.C.?",
                description: "You can order online from a variety of restaurants throughout Washington D.C.. Check on Grubhub.com and enter your address to see if Grubhub delivers to your office."
            },
            {
                header: "What are some tips for planing for back to office in Washington D.C.?",
                description: "<span>When it comes to planning for <a href='/back-to-work/'>back to office</a> in Washington D.C., it's important to put safety and flexiblity first. This is especially important for office meal programs. Following the guidance of our epidemiologist and local health and government officials, we can help you create a food plan that promotes a safe ordering experience for everyone.</span>"
            },
            {
                header: "Who offers corporate catering in Washington D.C.?",
                description: "<span>Many local restaurants offer corporate catering in Atlanta through a Grubhub Corporate Account! Simply visit <a href='http://grubhub.com/'>grubhub.com</a>, put in your address, and filter for catering to see if your favorite restaurant is available for corporate catering! </span>"
            },
            {
                header: "How do you place office group orders in Washington D.C.?",
                description: "<span>Grubhub group orders are offered through <a href='/'>Grubhub Corporate Accounts</a>. Employees can place individual orders from pre-selected [Atlanta] restaurants and the orders will get delivered together at the same time. Each order will arrive individually bagged and labeled for easy distribution. Learn more about <a href='/group-orders'>group ordering</a> and start an order today.</span>"
            }

        ]
    },
]


module.exports.pageData = localPages;