import styled, { css } from 'styled-components';
import { queries } from "../../../breakpoints"

export const StyledSection = styled.section`
    padding: var(--section-verticalPadding) 0;
    position: relative;
    svg.sectionBottomGraphic{
      position: absolute;
      width: 100%;
      height: 140px;
      bottom: -60px;
      z-index: 1;
    }

  @media (${queries.max_break_md}) {
    ${props => props.customPaddingMobile ? css`padding: ${props.customPaddingMobile} !important;` : ''}
    svg.sectionBottomGraphic{
      height: 50px;
      bottom: -20px;
    }
  }

  @media (${queries.max_break_lg_big}) {
    ${props => props.customPaddingMobile ? css`padding: ${props.customPaddingMobile} !important;` : ''}
    svg.sectionBottomGraphic{
      height: 50px;
      bottom: -20px;
    }
  }
`;
