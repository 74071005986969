import React from 'react';

export const CloseIcon = () => {
    return(
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Standalone_Corporate_Seamless_Banner_DSK" transform="translate(-1141.000000, -122.000000)" stroke="var(--color-secondary)" strokeWidth="3">
                    <g id="Group-4" transform="translate(1143.000000, 124.000000)">
                        <line x1="13.8931674" y1="9.9475983e-14" x2="0" y2="14.386796" id="Line-3-Copy"></line>
                        <line x1="13.8931674" y1="9.9475983e-14" x2="1.77635684e-15" y2="14.386796" id="Line-3-Copy" transform="translate(6.946584, 7.193398) scale(-1, 1) translate(-6.946584, -7.193398) "></line>
                    </g>
                </g>
            </g>
        </svg>
    );
}
