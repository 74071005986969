import styled from 'styled-components';
import { Tabs, Tab } from "@material-ui/core"
import SectionWrapper from "./components/sections/SectionWrapper"
import {FaChevronDown} from 'react-icons/fa';

export const StepsSection = styled.div`
  padding: 0 40px;
`;

export const Step = styled.div`
  &:not(:last-child){
    margin-bottom: 35px;
  }
`;

export const StepNumber = styled.h4`
  text-transform: uppercase;
  color: #612889;
  letter-spacing: 1.5px;
  font-size: var(--fontSize-2);
  font-weight: bold;
  margin: 0;
`;

export const StepDescription = styled.p`
  font-size: var(--fontSize-3);
`;

export const SectionHeadline = styled.h2`
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px;
`;

// ----------- Styles the Material UI Tabs
export const StyledTabHeader = styled(Tab)`
    flex-grow: 1;
    max-width: 50%!important;
    span{
        text-transform: none;
        font-family: var(--font-body);
        font-weight: bold;
        font-size: var(--fontSize-1);
        min-width: unset;
    }
`;

export const StyledTabSection = styled(SectionWrapper)`
    .PrivateTabIndicator-colorSecondary-3, .MuiTabs-indicator {
        background-color: var(--color-primary);
        z-index: 2;
    }
`;

export const StyledTabs = styled(Tabs)`
    margin-bottom: 20px;
    position: relative;

    &:after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: var(--color-primary-dark);
        opacity: .2;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
`;

export const StyledChevron = styled(FaChevronDown)`
    color: var(--color-primary);
`;

