import PropTypes from 'prop-types';
import React from 'react';
import { StyledButton } from './Button.styles'

const Button = (props) => (
  <Link 
    to={props.to} 
    type={props.type}
    style={{ ...props.style, textDecoration: 'none' }} 
    onClick={props.onClick}
    // className={`gh-button  gh-button--${props.type} ${props.className}`}
  >
    <span className={`gh-button__text`}>{props.children}</span>
  </Link>
);

Button.propTypes = {
  to: PropTypes.string,
  type: PropTypes.string
};

Button.defaultProps = {
  to: null,
  type: "primary"
};

export default Button;


// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <StyledButton
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </StyledButton>
    )
  }
  if(to === null){
    // Setting this up so that we have the option to include a button nested inside of a 
    // element that has an <a> tag already so we don't have another <a> nested in it
    return(
      // Add the as="span" to the tag to change it from a link to a span
      <StyledButton as="span" {...other}>{children}</StyledButton>
      )
    }
    return (
    // Add the as="a" to the tag to change it from a link to a <a> tag
    <StyledButton as="a" href={to} {...other}>
      {children}
    </StyledButton>
  )
}
