import styled, { css } from 'styled-components';

import { Link as GatsbyLink } from "gatsby"
import { queries } from "../../../breakpoints";

const typeStyles = type => {
    switch(type){

        // Styles for primary button
        case "primary" :
            return css`
                background-color: var(--color-primary);
                color: white;
                &:hover{
                    color:white;
                    background-color:var(--color-primary-dark);
                }
                &:active{
                    background-color:var(--color-primary-darker);
                }
                @media (${queries.max_break_md}){
                    min-width:270px;
                }
                @media (max-width: 300px){
                    width:100%;
                    min-width: unset;
                }
                `

        // Styles for secondary button
        case "secondary" : 
            return css`
                background-color: transparent;
                color: var(--color-primary);
                border: 1px solid var(--color-primary);
                &:hover{
                    color:var(--color-primary-dark);
                    border-color:var(--color-primary-dark);
                }
                &:active{
                    color: var(--color-primary-darker);
                    border-color:var(--color-primary-darker);
                }
                @media (${queries.max_break_md}){
                    min-width:270px;
                }
                `
                
        // Styles for tertiary button
        case 'tertiary' :
            return css`
                background-color: transparent;
                color: var(--color-primary);
                padding:0;
                text-align: left;
                min-width: unset;
                &:hover{
                    color: var(--color-primary-dark);
                }
                &:active{
                    color: var(--color-primary-darker);
                }
                `
        
        default :
            return css`
                background-color: var(--color-primary);
                color: white;
                &:hover{
                    color:white
                }
            `
                
    }
} 

export const StyledButton = styled(GatsbyLink)`
    display: inline;
    padding: 12px 30px;
    border-radius: 100px;
    font-weight: bold;
    font-size: var(--fontSize-1);
    min-width: 175px;
    display: inline-block;
    text-align: center;
    transition: .1s all;
    &:hover {
        text-decoration: none;
    }
    // Render the appropriate styles for the button type
    ${props => typeStyles(props.type)}

    @media (${queries.max_break_sm}){
        min-width:unset;
    }
`;