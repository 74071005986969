import { AccordionDetails, AccordionSummary } from "@material-ui/core"
import { CompanyInfoPanel, Copyright, Disclaimer, ListHeader, Sitemap, SitemapRow, StyledDesktopAccordion, StyledFooter, StyledLink, TermsPrivacyPolicy } from './Footer.styles'
import { FooterTerms, MobileFooterLink, MobileMenuHeader, MobileSubmenu, StyledAccordion, StyledChevron, StyledMobileFooter } from "./MobileFooter.styles"
import React, { useContext } from "react"

import { AnalyticsContext } from "../../../context"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import localPages from '../../../../local-pages';

const Footer = props => {
  const { handleTealiumEvent } = useContext(AnalyticsContext)

  /**
   * TODO: Set this up with a general object array to control all of the menu items and loop through that to make the desktop and mobile menus so we don't need to duplicate everything on both menus
   * */

  return(
  <>
  <StyledFooter>
    <CompanyInfoPanel>
      <Link to="/">
        <StaticImage
          src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1710408371/HEADER_GH_CorporateAccounts_Horizontal_sRGB_White.png"
          alt="Grubhub/Seamless Corporate Accounts"
          placeholder="blurred"
          width={196}
        />
      </Link>
      <Copyright>
        © {new Date().getFullYear()} Grubhub All rights reserved
      </Copyright>
      <TermsPrivacyPolicy>
        <a href="https://www.grubhub.com/legal/terms-of-use">Terms of use</a>
        <a href="https://www.grubhub.com/legal/privacy-policy">
          Privacy policy
        </a>
      </TermsPrivacyPolicy>

      <Disclaimer>
        *supporting documentation is required <br/>
        **Based on an internal survey of Grubhub Corporate clients <br/>
	***Valid on eligible orders, additional fees may apply.
      </Disclaimer>
    </CompanyInfoPanel>
    <Sitemap>
      <SitemapRow className="row">
        <div className="footer__sitemap-section col-4">
          <ListHeader>Learn more</ListHeader>
          <ul className="footer__link-list">
            <StyledLink>
              <Link
                to="/contact-us/"
              >
                Contact us
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/about-us/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "about us" })}
              >
                About us
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/how-grubhub-works/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "how it works" })}
              >
                How it works
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/learning-center/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "learning center" })}
              >
                Learning center
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/faqs/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "faq" })}
              >
                FAQs
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/seamless-to-grubhub/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "seamless-to-grubhub" })}
              >
                Seamless Corporate
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/corporate-accounts-pricing/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "corporate-accounts-pricing" })}
              >
                Pricing and plans
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/grubhub-plus-for-employees/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "grubhub-plus-for-employees" })}
              >
                Grubhub+ for employees
              </Link>
            </StyledLink>
          </ul>
        </div>
        <div className="footer__sitemap-section col-4">
          <ListHeader>Ways to order</ListHeader>
          <ul className="footer__link-list">
            <StyledLink>
              <Link
                to="/catering/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ways to order", eventlabel: "catering" })}
              >
                Catering
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/group-orders/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ways to order", eventlabel: "group orders" })}
              >
                Group orders
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/individual-meals/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ways to order", eventlabel: "individual meals" })}
              >
                Individual meals
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/tax-exempt-food-delivery/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "tax-exempt-food-delivery" })}
              >
                Tax exempt solutions
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/employee-gift-cards/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "employee-gift-cards" })}
              >
                Gift cards for employees
              </Link>
            </StyledLink>
          </ul>
        </div>
        <div className="footer__sitemap-section col-4">
          <ListHeader>Trending blog posts</ListHeader>
          <ul className="footer__link-list">
            <StyledLink>
              <Link
                to="/blog/8-gift-ideas-to-support-employees-return-to-the-office/"
              >
                Return to office gift ideas
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/blog/5-team-building-summer-activities-to-boost-employee-morale/"
              >
                Team-building summer activities
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/blog/how-to-host-a-virtual-lunch-and-learn/"
              >
                Virtual lunch and learn
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/blog/5-company-celebration-ideas-to-boost-employee-morale/"
              >
                Company celebration tips
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/blog/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "blog" })}
              >
                All blog posts
              </Link>
            </StyledLink>
          </ul>
        </div>
      </SitemapRow>
      <SitemapRow className="row">
        <div className="footer__sitemap-section col-4">
          <ListHeader>Partner with us</ListHeader>
          <ul className="footer__link-list">
            <StyledLink>
              <Link
                to="/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "partner with us", eventlabel: "for companies" })}
              >
                For companies
              </Link>
            </StyledLink>
            <StyledLink>
              <a
                href="https://get.grubhub.com/?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_corporate-client-lead-gen-site&utm_term=nopromo_footer-navigation"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "partner with us", eventlabel: "for restaurants" })}
              >
                For restaurants
              </a>
            </StyledLink>
            <StyledLink>
              <a
                href="https://driver.grubhub.com/?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_corporate-client-lead-gen-site&utm_term=nopromo_footer-navigation"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "partner with us", eventlabel: "for drivers" })}
              >
                For drivers
              </a>
            </StyledLink>
          </ul>
        </div>
        <div className="footer__sitemap-section col-4">
          <ListHeader>Ordering occasions</ListHeader>
          <ul className="footer__link-list">
            <StyledLink>
              <Link
                to="/meals-for-digital-events/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "digital events" })}
              >
                Digital events
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/perks/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "corporate perks" })}
              >
                Corporate perks
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/meals-for-remote-employees/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "remote employees" })}
              >
                Remote employees
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/back-to-work/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "safe ordering" })}
              >
                Safe ordering
              </Link>
            </StyledLink>
            <StyledLink>
              <Link
                to="/hybrid-teams/"
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "hybrid teams" })}
              >
                Hybrid teams
              </Link>
            </StyledLink>
          </ul>
        </div>
        <div className="footer__sitemap-section col-4">
          {/* Using some mobile styles here to avoid completely rewriting styles for the accordion */}
          <StyledDesktopAccordion square>
            <AccordionSummary aria-controls="cities-content" id={`cities-header`}expandIcon={<StyledChevron size={20} />}>
              <ListHeader>Cities we serve</ListHeader>
            </AccordionSummary>
            <AccordionDetails>
              <MobileSubmenu>
                {localPages.pageData.map((city, i) => (
                  <MobileFooterLink key={i}>
                    <Link
                      to={city.slug}
                      onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "cities we serve", eventlabel: `${city.city}` })}
                    >
                      {city.city}
                    </Link>
                  </MobileFooterLink>
                ))}
              </MobileSubmenu>
            </AccordionDetails>
          </StyledDesktopAccordion>
        </div>
      </SitemapRow>
    </Sitemap>
  </StyledFooter>
  <MobileFooter/>
  </>
)}

export default Footer


const MobileFooter = () => {
  const { handleTealiumEvent } = useContext(AnalyticsContext)
  return(
  <StyledMobileFooter>
    <Link to="/">
      <StaticImage
        src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652230775/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Homepage/LOGO-GH-SL-Corp-WHITE.png"
        alt="Grubhub/Seamless Corporate Accounts"
        placeholder="blurred"
        width={196}
      />
    </Link>

    <StyledAccordion square>
      <AccordionSummary aria-controls="learn-more-content" id="learn-more-header" expandIcon={<StyledChevron/>}>
        <MobileMenuHeader>Learn more</MobileMenuHeader>
      </AccordionSummary>
      <AccordionDetails>
        <MobileSubmenu>
          <MobileFooterLink>
            <Link
              to="/contact-us/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "contact us" })}
            >
              Contact us
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/about-us/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "about us" })}
            >
              About us
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/how-grubhub-works/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "how it works" })}
            >
              How it works
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="#"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "learning center" })}
            >
              Learning center
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/faqs/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "faq" })}
            >
              FAQs
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/seamless-to-grubhub/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "seamless-to-grubhub" })}
            >
              Seamless Corporate
            </Link>
          </MobileFooterLink>
        </MobileSubmenu>
      </AccordionDetails>
    </StyledAccordion>

    <StyledAccordion square>
      <AccordionSummary aria-controls="ways-content" id="ways-header" expandIcon={<StyledChevron/>}>
        <MobileMenuHeader>Ways to order</MobileMenuHeader>
      </AccordionSummary>
      <AccordionDetails>
        <MobileSubmenu>
          <MobileFooterLink>
            <Link
              to="/catering/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ways to order", eventlabel: "catering" })}
            >
              Catering
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/group-orders/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ways to order", eventlabel: "group orders" })}
            >
              Group orders
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/individual-meals/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ways to order", eventlabel: "individual meals" })}
            >
              Individual meals
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link to="/tax-exempt-food-delivery/">
              Tax exempt solutions
            </Link>
          </MobileFooterLink>
        </MobileSubmenu>
      </AccordionDetails>
    </StyledAccordion>

    <StyledAccordion square>
      <AccordionSummary aria-controls="trending-blog-posts-content" id="trending-blog-posts-header" expandIcon={<StyledChevron />}>
        <MobileMenuHeader>Trending blog posts</MobileMenuHeader>
      </AccordionSummary>
      <AccordionDetails>
        <MobileSubmenu>
          <MobileFooterLink>
            <Link
              to="/blog/8-gift-ideas-to-support-employees-return-to-the-office/"
            >
              Return to office gift ideas
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/blog/5-team-building-summer-activities-to-boost-employee-morale/"
            >
              Team-building summer activities
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/blog/how-to-host-a-virtual-lunch-and-learn/"
            >
              Virtual Lunch and Learn
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/blog/5-company-celebration-ideas-to-boost-employee-morale/"
            >
              Company celebration tips
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/blog/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "learn more", eventlabel: "blog" })}
            >
              All blog posts
            </Link>
          </MobileFooterLink>
        </MobileSubmenu>
      </AccordionDetails>
    </StyledAccordion>

    <StyledAccordion square>
      <AccordionSummary aria-controls="ordering-content" id="ordering-header" expandIcon={<StyledChevron/>}>
        <MobileMenuHeader>Ordering occasions</MobileMenuHeader>
      </AccordionSummary>
      <AccordionDetails>
        <MobileSubmenu>
          <MobileFooterLink>
            <Link
              to="/meals-for-digital-events/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "digital events" })}
            >
              Digital events
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/perks/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "corporate perks" })}
            >
              Corporate Perks
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/meals-for-remote-employees/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "remote employees" })}
            >
              Remote employees
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/back-to-work/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "safe ordering" })}
            >
              Safe ordering
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <Link
              to="/hybrid-teams/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "ordering occasions", eventlabel: "hybrid teams" })}
            >
              Hybrid teams
            </Link>
          </MobileFooterLink>
        </MobileSubmenu>
      </AccordionDetails>
    </StyledAccordion>

    <StyledAccordion square>
      <AccordionSummary aria-controls="partner-content" id="partner-header" expandIcon={<StyledChevron/>}>
        <MobileMenuHeader>Partner with us</MobileMenuHeader>
      </AccordionSummary>
      <AccordionDetails>
        <MobileSubmenu>
          <MobileFooterLink>
            <Link
              to="/"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "partner with us", eventlabel: "for companies" })}
            >
              For Companies
            </Link>
          </MobileFooterLink>
          <MobileFooterLink>
            <a
              href="https://get.grubhub.com/?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_corporate-client-lead-gen-site&utm_term=nopromo_footer-navigation"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "partner with us", eventlabel: "for restaurants" })}
            >
              For Restaurants
            </a>
          </MobileFooterLink>
          <MobileFooterLink>
            <a
              href="https://driver.grubhub.com/?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpmarketing_corporate-client-lead-gen-site&utm_term=nopromo_footer-navigation"
              onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "partner with us", eventlabel: "for drivers" })}
            >
              For Drivers
            </a>
          </MobileFooterLink>
        </MobileSubmenu>
      </AccordionDetails>
    </StyledAccordion>

    <StyledAccordion square>
      <AccordionSummary aria-controls="cities-content" id="cities-header" expandIcon={<StyledChevron/>}>
        <MobileMenuHeader>Cities we serve</MobileMenuHeader>
      </AccordionSummary>
      <AccordionDetails>
        <MobileSubmenu>
          {localPages.pageData.map((city, i) => (
            <MobileFooterLink key={i}>
              <Link
                to={city.slug}
                onClick={() => handleTealiumEvent({ eventcategory: "footer interaction", eventaction: "cities we serve", eventlabel: `${city.city}` })}
              >
                {city.city}
              </Link>
            </MobileFooterLink>
          ))}
        </MobileSubmenu>
      </AccordionDetails>
    </StyledAccordion>

      <FooterTerms>
        <p><a href="https://www.grubhub.com/legal/terms-of-use">Terms of use</a></p>
        <p><a href="https://www.grubhub.com/legal/privacy-policy">Privacy policy</a></p>
        <p>
          © {new Date().getFullYear()} Grubhub All rights reserved
        </p>
        <p>*supporting documentation is required</p>
        <p>**Based on an internal survey of Grubhub Corporate clients</p>
	<p>***Valid on eligible orders, additional fees may apply.</p>
      </FooterTerms>
  </StyledMobileFooter>
  )
}
