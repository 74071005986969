import { Accordion } from "@material-ui/core"
import { queries } from "../../../breakpoints"
import styled from 'styled-components';

export const StyledFooter = styled.footer`
    background-color: var(--color-secondary);
    color: white;
    display: flex;
    flex-wrap: wrap-reverse;
    padding: var(--spacing-6);
    font-size: var(--fontSize-0);
    @media (${queries.max_break_md}){
        display: none;
    }
`;

export const CompanyInfoPanel = styled.div`
    flex: 1;
`;

export const Copyright = styled.p`
    margin-top: 20px;
    margin-bottom: 0;
`;

export const TermsPrivacyPolicy = styled.div`
    a {
        color: white;
        margin-right: 7px;
    }
`;

export const Disclaimer = styled.p`
    width: 300px;
    margin-top: 20px;
    @media (${queries.max_break_md}){
        width: 100%;
    }
`;

export const Sitemap = styled.div`
    flex: 1;
    min-width: 570px;
    max-width: 900px;
    margin-bottom: 20px;
`;

export const SitemapRow = styled.div`
    &:not(:first-child) {
        margin-top: 20px;
    }
`;

export const ListHeader = styled.p`
    font-weight: bold;
    margin-bottom: 0;
    font-size: var(--fontSize-1);
    color:white;
`;

export const StyledLink = styled.li`
    list-style-type: none;
    margin-bottom: 0;

    a {
        color: white;
    }
`;
export const StyledDesktopAccordion = styled(Accordion)`
    // Increase specificity with '&&&' to override styles from MUI
    &&& {
        box-shadow: none;
        background-color: transparent;
        margin:0;
        width:100%;
        border-bottom: none;
    }
    &:before{
        display: none;
    }
    .MuiAccordionSummary-root, .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded{
        margin: 0;
        padding:0;
    }
    .MuiButtonBase-root, .MuiAccordionSummary-root.Mui-expanded{
        min-height: unset;
    }
    .MuiIconButton-root{
        padding: 0 12px;
    }
    .MuiAccordionDetails-root{
        padding: 10px 0;
    }
`;