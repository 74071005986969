/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { AnalyticsContext } from "../context"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import formatFaqList from "./faqHelper"

const Seo = ({ description, image, lang, link, meta, title, noindex, nofollow, utag_data, faqSeoList }) => {
  const { handlePageView, pageHistory, saveUrlToPageHistory } = useContext(AnalyticsContext)

  const { wp, wpUser } = useStaticQuery(
    graphql`
    query {
      wp {
        generalSettings {
          title
          description
        }
      }
      
      # if there's more than one user this would need to be filtered to the main user
      wpUser {
        twitter: name
      }
    }
    `
  )

  // Default values are set in the WP backend
  const metaDescription = description || wp.generalSettings?.description
  const defaultTitle = wp.generalSettings?.title


  // Using this variables to prevent duplicate page views on the same page
  const [ currentPage, setCurrentPage ] = useState("")

  /**
   * On render, check if the currentPage is different from the url
   * If it is then send a handlePageView event
   * */
  useEffect( () => {

    const url = typeof window !== 'undefined' ? window.location.href : '';
    if(currentPage !== url){
      // Tealium event on page load
      handlePageView(utag_data ? utag_data : {
        pageGroup: "grubhub - lead generation",
        subGroup: "untracked",
        pageName: title,
        brand: "grubhub"
      });

      /**
       * If on a new page, save currentPage (which hasn't been changed to the new page yet)
       * to the previousPage in AnalyticsContext
       * */
      saveUrlToPageHistory(window.location.pathname);

      // Set the currentPage to the new url
      setCurrentPage(url);
    }
  }, [currentPage, handlePageView, title, utag_data, saveUrlToPageHistory, pageHistory])

  const getRobotsInfo = () => {
    //If both noindex and nofollow
    if(noindex && nofollow){
      return "noindex, nofollow"
    }
    //If just noindex
    else if(noindex && !nofollow){
      return "noindex, follow"
    }
    //If just nofollow
    else if(!noindex && nofollow){
      return "index, nofollow"
    }
    else{
      return "index, follow"
    }
  }

  return (
    <>
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s` : null}

      link={[
        {
          rel: `alternate`,
          href: `https://corporate.grubhub.com`,
          hreflang: lang,
        }
      ].concat( link )}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: wpUser?.twitter || `Grubhub`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: `${getRobotsInfo()}`
        },
        {
          name: `googlebot`,
          content: `${getRobotsInfo()}`
        }
      ].concat(meta)}

    >
      <script type="application/ld+json">
         {faqSeoList && formatFaqList(faqSeoList)}
       </script>
      <script>
        {`(function (w, d, t) {
            _ml = w._ml || {};
            _ml.eid = '81430';
            _ml.cid = '834df1ce-74a1-4329-9cf6-8d77692c9f06';
            var s, cd, tag; s = d.getElementsByTagName(t)[0]; cd = new Date();
            tag = d.createElement(t); tag.async = 1;
            tag.src = 'https://ml314.com/tag.aspx?' + cd.getDate() + cd.getMonth();
            s.parentNode.insertBefore(tag, s);
        })(window, document, 'script');`}
    </script>
    </Helmet>
    </>
  )
}

Seo.defaultProps = {
  lang: `en`,
  link: [],
  meta: [],
  title: "Grubhub Corporate Accounts",
  image: 'https://res.cloudinary.com/grubhub-marketing/image/upload/v1632250073/Grubhub%20Corporate%20Website/2021/SEO/SEO_fallback.png',
  description: `Sign up for a Grubhub Corporate Account now!`,
  noindex: false,
  nofollow: false,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  link: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  noindex: PropTypes.bool,
  nofollow: PropTypes.bool,
  utag_data: PropTypes.shape({
    pageGroup: PropTypes.string.isRequired,
    subGroup: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
  }).isRequired
}

export default Seo
