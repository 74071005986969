import { queries }  from "../../../../breakpoints"
import styled from 'styled-components';

export const MainBackgroundContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    top: 0px;
    left: 0px;
    min-height: 100vh;
    width: 100%;
    z-index: 2;
    background-color: #000000;
    opacity: 0.3;
`;

export const MainBannerContainer = styled.div`
    min-height: 358px;
    position: absolute;
    top: 100px;
    left: 0px;
    width: 100%;
    background-color: #F9F5F4;
    background-image: url("https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/Banner-img-dsk-bkg-F9F5F4.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    opacity: 1;
    z-index: 2001;
    @media (${queries.max_break_md}){
        min-height: 120vh;
        text-align:center;
        align-items: flex-start;
        background-image: url("https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/Banner-img-mob-bkg-FAF6F5.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
        z-index: 9999;
    }
    @media (${queries.max_break_sm}){
        min-height: 120vh;
        background-image: url("https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/Banner-img-mob-bkg-FAF6F5.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 9999;
    }
`;

export const CloseButton = styled.div`
    padding: 20px;
`;

export const Title = styled.h3`
    font-family: "Scandia Bold",sans-serif;
    font-weight: 700;
`;

export const Text = styled.p`
    font-family: "Scandia Regular",sans-serif;
    font-weight: 600;
`;

export const CTAButton = styled.div`
    cursor: pointer;
    @media (${queries.max_break_md}){

    }
    @media (${queries.max_break_sm}){

    }
`;

export const LeftSection = styled.div`
    width: 60%;
    min-height: 358px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media (${queries.max_break_md}){
        width: 100%;
        padding: 0px 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    @media (${queries.max_break_sm}){
        width: 100%;
        padding: 0px 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const RightSection = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    @media (${queries.max_break_md}){
        width: 100%;
    }
    @media (${queries.max_break_sm}){
        width: 100%;
    }
`;