import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
// import './SectionWrapper.scss';
import { StyledSection } from "./SectionWrapper.styles.js"

/**
 * This is used as a wrapper for custom built components to control background color and to keep vertical padding uniform across all components
 * @param {string} className = pass in a className string to control with scss in the componeent
 * @param {string} backgroundColor = set the backgroundColor of the section
 * @param {string} maxWidth = set the maxWidth of the content in the section
 * @returns
 */

const SectionWrapper = (props) => {

  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined"
  // Set the min-width to the size min desktop size from Bootstrap
  const mediaMatch = isBrowser ? window.matchMedia('(min-width: 992px)') : true;
  const [ matches, setMatches ] = useState(isBrowser ? mediaMatch.matches : true);

  // Adding eventlistener to check screen size and update state based on above mediaMatch and the size of the screen
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });


  const getBottomSectionGraphic = ( color, negativeColor ) => {
    return (
        <svg className='sectionBottomGraphic' width={'100%'} height={'100%'} preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150" style={{backgroundColor: negativeColor}}>
          <path fill={color} fill-opacity="1" d="M0,150L1440,0L1440,0L0,0Z" vector-effect="non-scaling-stroke"></path>
        </svg>
    )
  }

  const sectionStyleCSS = { backgroundColor: props.backgroundColor, padding: props.sectionPadding, ...props.style };

  return(
    <StyledSection
      customPaddingMobile={props.customPaddingMobile}
      id={props.id}
      className={props.className}
      style={sectionStyleCSS}>
                                        {/** using the container style object below to
                                         * only apply the custom maxWidth style to
                                         * desktop.
                                         */}
      <div className="container" style={styles.container(matches, props.maxWidth)}>
        {props.children}
      </div>

      { props.bottomGraphic === true && getBottomSectionGraphic( props.bottomGraphicColor, props.bottomGraphicNegativeColor ) }

    </StyledSection>
  )
};

const styles = {
  container: (isDesktop, width) => ({
    maxWidth: isDesktop ? width : undefined
  })
}

SectionWrapper.propTypes = {
  className: PropTypes.string,
  customPaddingMobile: PropTypes.string,
  backgroundColor: PropTypes.string,
  sectionPadding: PropTypes.string,
  bottomGraphic: PropTypes.bool,
  bottomGraphicColor: PropTypes.string,
  bottomGraphicNegativeColor: PropTypes.string,
};

SectionWrapper.defaultProps = {
  className: '',
  customPaddingMobile: '',
  backgroundColor: 'white',
  sectionPadding: null,
  bottomGraphic: false,
  bottomGraphicColor: '#ff8800',
  bottomGraphicNegativeColor: '#F5F3F1'
};

export default SectionWrapper;
