/**
 * File to hold global helper functions
 */



// Strips the HTML tags out of a string for displaying
export const stripHtmlTags = (str) => {
    const strippedString = str.replace(/(<([^>]+)>)/gi, "");

    return strippedString
}



// Replace any unicode characters with their string equivalent
var escapeChars = { lt: '<', gt: '>', quot: '"', apos: "'", amp: '&' };
export function unescapeHTML(str) {//modified from underscore.string and string.js
    return str.replace(/\&([^;]+);/g, function(entity, entityCode) { //eslint-disable-line 
        var match;
        if ( entityCode in escapeChars) {
            return escapeChars[entityCode];
        } else if ( match = entityCode.match(/^#x([\da-fA-F]+)$/)) { //eslint-disable-line 
            return String.fromCharCode(parseInt(match[1], 16));
        } else if ( match = entityCode.match(/^#(\d+)$/)) { //eslint-disable-line 
            return String.fromCharCode(~~match[1]);
        } else {
            return entity;
        }
    });
}

export function getPageSlug() {
    var url = typeof window !== 'undefined' ? window.location.pathname : '';
    // Strip the leading '/'
    url = url.replace(/^\/+/g, '')

    // If the url is empty after stripping the leading / then it's the homepage
    if(url === ''){
        url = 'homepage'
    }
    return url;
}