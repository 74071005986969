function formatFaqList(list) {
  let obj = {
    "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": []
  };

  list.forEach((item) => {
    obj.mainEntity.push( {
      "@type": "Question",
      "name": item.header,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.description
      }
    })
  });

  return JSON.stringify(obj);
}

export default formatFaqList;