import styled from 'styled-components';
import { queries } from "../../../breakpoints"
import { Accordion, AccordionDetails } from "@material-ui/core"
import {FaChevronDown} from 'react-icons/fa';
export const StyledMobileFooter = styled.div`
    background-color: var(--color-secondary);
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow:column;
    padding: var(--spacing-8);
    font-size: var(--fontSize-0);
    @media (${queries.min_break_md}){
        display: none;
    }
`;

export const MobileSubmenu = styled.div`
    display:flex;
    flex-flow:column;
    width: 100%;
`;

export const MobileMenuHeader = styled.h5`
    font-weight: bold;
    padding: 0;
    font-size:var(--fontSize-1);
    letter-spacing:.19px;
    color:white;
`;


export const StyledAccordion = styled(Accordion)`
    // Increase specificity with '&&&' to override styles from MUI
    &&& {
        box-shadow: none;
        background-color: transparent;
        margin:0;
        margin:10px 0;
        width:100%;
        border-bottom: 1px solid white;
    }
    &:before{
        display: none;
    }
`;
export const StyledAccordionDetails = styled(AccordionDetails)`
    &&& {
        margin: 0;
        padding: 0;
    }
`;

export const MobileFooterLink = styled.p`
    a{ color:white }
`;

export const StyledChevron = styled(FaChevronDown)`
    color:white;
`;

export const FooterTerms = styled.div`
    margin: 25px 0;
    align-self: flex-start;
  a { color:white;}
`;