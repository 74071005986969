import { MainBackgroundContainer, MainBannerContainer, CloseButton, Title, Text, CTAButton, LeftSection, RightSection } from "./Banner.styles"
import React from 'react';
import { AnalyticsContext } from "../../../../context";
import { CloseIcon } from "../../../../../content/assets/svg/close";
import Button from "../../../global/Button";

const Banner = ({ bannerInfo }) => {
  const [showBanner, setShowBanner] = React.useState(true);
  const { handleTealiumEvent } = React.useContext(AnalyticsContext);

  const handleBannerClose = (e) => {
    e.preventDefault();
    setShowBanner(false);
  }

  return(
    <div>
      {
        showBanner ?
          <div>
            <MainBannerContainer>
              <LeftSection>
                <Title>{bannerInfo.title}</Title>
                <Text>{bannerInfo.text1}</Text>
                <Text>{bannerInfo.text2}</Text>
                <Button
                  type="primary"
                  onClick={(e) => handleBannerClose(e)}
                >
                  Continue
                </Button>
              </LeftSection>
              <RightSection>
                <CloseButton onClick={(e) => handleBannerClose(e)}>
                  <CloseIcon />
                </CloseButton>
              </RightSection>
            </MainBannerContainer>
            <MainBackgroundContainer />
          </div> :
          null
      }
    </div>
  );
};

export default Banner;
